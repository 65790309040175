import Icons from "@/components/Icons";
import { IconList } from "@/types/icons";
import { SubMenuItem } from "@/types/menu";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";

type Props = {};

const CommonItem = (props: SubMenuItem) => {
  return (
    <NavigationMenu.Link asChild>
      <a
        className={`common group row-${props.row} ${
          props.asset ? "!flex-row gap-2" : ""
        }`}
        href={props.href}
      >
        {props.asset && (
          <span className="asset-icon">
            <span className="transition-all duration-300 group-hover:scale-[1.15]">
              <Icons icon={props.asset as IconList} />
            </span>
          </span>
        )}
        <div className="flex h-full w-full flex-col items-start justify-between gap-2">
          <p>{props.label}</p>
          <span>{props.extra}</span>
        </div>
      </a>
    </NavigationMenu.Link>
  );
};

export default CommonItem;
