"use client";
import Button from "@/components/Button";
import Logo from "@/components/Logo";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  ButtonSize,
  ButtonType,
  ButtonVariation,
  ButtonVisual,
} from "@/types/button";
import { IconList } from "@/types/icons";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";

import { Accordion } from "@/components/ui/accordion";
import { MenuGroup } from "@/types/menu";
import { ScanMatelso } from "@/utils/events";
import MobileMenuItem from "./MobileMenuItem";

type Props = {
  className?: string;
  menu: MenuGroup;
};

const MobileMenu = ({ menu }: Props) => {
  // console.log(menu);

  return (
    <Sheet>
      <SheetTrigger
        asChild
        onClick={() => {
          ScanMatelso();
        }}
      >
        <button aria-label="Mobile Menu">
          <HamburgerMenuIcon
            className="h-12 w-12 p-2 text-white"
            id="main_menu_burger"
          />
        </button>
      </SheetTrigger>
      <SheetContent className="flex w-[360px] gap-2 px-5 py-2 ">
        <SheetHeader className="flex flex-row items-center justify-between">
          <Logo type="logo" className="h-10 w-auto" />
          <SheetPrimitive.Close className="h-12 w-12">
            <Cross1Icon className="h-8 w-8 text-primary" />
          </SheetPrimitive.Close>
        </SheetHeader>
        <div className="flex flex-grow overflow-hidden ">
          <Accordion
            type="single"
            collapsible
            className="flex h-full max-h-full w-full flex-col overflow-scroll font-semibold"
          >
            {menu.itemsMobile.map((item, index) => (
              <MobileMenuItem
                {...item}
                value={`item-${index + 1}`}
                key={`menu_mobile_${index}`}
              />
            ))}
          </Accordion>
        </div>
        <SheetFooter>
          <Button
            visual={ButtonVisual.SECONDARY}
            variation={ButtonVariation.SOLID}
            size={ButtonSize.LARGE}
            label="Unverbindliche Planung starten"
            type={ButtonType.WIZARD}
            link=""
            icon={IconList.ARROW_RIGHT}
            className="mobile-phone h-14 w-full"
          />
          <Button
            visual={ButtonVisual.SECONDARY}
            variation={ButtonVariation.SOLID}
            size={ButtonSize.LARGE}
            label="0800 88 44 04"
            extraLabel="0-24 Uhr"
            type={ButtonType.PHONE}
            link=""
            icon={IconList.PHONE}
            iconPosition="left"
            className="mobile-phone h-14 w-full"
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;
