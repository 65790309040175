"use client";
import { FooterMenuGroup } from "@/types/navigation";

type Props = {};

const FooterDesktopList = (props: FooterMenuGroup) => {
  return (
    <div>
      <p className="title text-lg font-semibold tracking-[1px]">
        {props.title}
      </p>
      <div
        className={`columns-1 ${
          props.list?.col === 1
            ? "md:columns-1"
            : props.list?.col === 2
            ? "md:columns-2"
            : "md:columns-3"
        } gap-8`}
      >
        {props.list?.items.map((item, index) => (
          <div className="pb-1" key={`footer_item_${index}`}>
            <a
              className="cursor-pointer text-[14px] hover:underline"
              href={item.href}
              id={item.id}
            >
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterDesktopList;
