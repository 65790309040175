import Icons from "@/components/Icons";
import { IconList } from "@/types/icons";
import { SubMenuItem } from "@/types/menu";
import { includeLinkSearchParams } from "@/utils/wizard";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

type Props = {};

const funeralLabels = {
  burial: "",
  cremation: "",
  treeFuneral: "",
  seaFuneral: "",
};
const FuneralTypeItem = (props: SubMenuItem) => {
  return (
    <NavigationMenu.Link asChild>
      <a
        className={`funeral-type group row-${props.row}`}
        href={includeLinkSearchParams(props.link!)}
        id={`main-menu-main-menu-funeraltypes-${props.label?.toLocaleLowerCase()}`}
      >
        <span className="icon">
          <span className="transition-all duration-300 group-hover:scale-[1.15]">
            <Icons icon={props.funeralType as IconList} />
          </span>
        </span>
        <span className="content">
          <p className="title">
            {props.label}{" "}
            <p className="price">
              ab <strong>{props.price} €</strong>, exkl. externe Kosten
            </p>
          </p>
          <p className="content">{props.content}</p>
        </span>
      </a>
    </NavigationMenu.Link>
  );
};

export default FuneralTypeItem;
