"use client";
import Button from "@/components/Button";
import Logo from "@/components/Logo";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet";
import { checkIsAdmin } from "@/lib/auth0-utils";
import { Session } from "@auth0/nextjs-auth0";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import MobileCommonUserMenu from "./Components/MobileCommonUserMenu";

import {
  ButtonSize,
  ButtonType,
  ButtonVariation,
  ButtonVisual,
} from "@/types/button";
import { IconList } from "@/types/icons";
import { ChevronLeft } from "lucide-react";

type Props = {
  user: Session;
};
const MobileUserMenu = ({ user }: Props) => {
  return (
    <Sheet>
      <SheetTrigger className="ml-auto text-white">
        <div className="relative flex cursor-pointer flex-row items-center gap-1">
          <img
            src={user.user.picture}
            width={48}
            height={48}
            alt={user.user.name}
            className="relative h-9 w-9 rounded-full border-2 border-white shadow-lg"
          />
          <div className="absolute bottom-0 left-0 rounded-full bg-white text-secondary-700">
            <ChevronLeft className="h-4 w-4" />
          </div>
        </div>
      </SheetTrigger>
      <SheetContent className="flex w-[360px] gap-2 px-5 py-2 ">
        <SheetHeader className="flex flex-row items-center justify-between">
          <Logo type="logo" className="h-10 w-auto" />
          <SheetPrimitive.Close className="h-12 w-12">
            <Cross1Icon className="h-8 w-8 text-primary" />
          </SheetPrimitive.Close>
        </SheetHeader>
        <div className="flex flex-grow overflow-hidden">
          <MobileCommonUserMenu />
        </div>
        <SheetFooter>
          <Button
            visual={ButtonVisual.SECONDARY}
            variation={ButtonVariation.SOLID}
            size={ButtonSize.MEDIUM}
            label="ÜBERSICHT"
            type={ButtonType.INTERNAL}
            link="/dashboard"
            icon={IconList.ARROW_RIGHT}
            className="mobile-phone h-14 w-full"
          />
          {checkIsAdmin(user) && (
            <Button
              visual={ButtonVisual.PRIMARY}
              variation={ButtonVariation.SOLID}
              size={ButtonSize.MEDIUM}
              label="GENERATOR"
              type={ButtonType.INTERNAL}
              link="/addons/generator"
              icon={IconList.ARROW_RIGHT}
              className="mobile-phone h-14 w-full"
            />
          )}
          {/* {checkIsAdmin(user) && (
            <Button
              visual={ButtonVisual.PRIMARY}
              variation={ButtonVariation.SOLID}
              size={ButtonSize.MEDIUM}
              label="KS"
              type={ButtonType.INTERNAL}
              link="/addons/ks"
              icon={IconList.ARROW_RIGHT}
              className="mobile-phone h-14 w-full"
            />
          )} */}
          <Button
            visual={ButtonVisual.TERTIARY}
            variation={ButtonVariation.SOLID}
            size={ButtonSize.MEDIUM}
            label="ABMELDEN"
            type={ButtonType.INTERNAL}
            link="/api/auth/logout"
            icon={IconList.ARROW_RIGHT}
            className="mobile-phone h-14 w-full"
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default MobileUserMenu;
