"use client";
import Button from "@/components/Button";
import {
  ButtonSize,
  ButtonType,
  ButtonVariation,
  ButtonVisual,
} from "@/types/button";
import { IconList } from "@/types/icons";

type Props = {
  isIphone?: boolean;
};

const PhoneBar = (props: Props) => {
  return (
    <div id="phone-bar" data-extended={props.isIphone} className={`phone-bar`}>
      <span className="grid w-full grid-cols-2 gap-1.5">
        <Button
          visual={ButtonVisual.PRIMARY}
          variation={ButtonVariation.SOLID}
          label="Alle Akzeptieren"
          link="link"
          size={ButtonSize.MEDIUM}
          type={ButtonType.PHONE}
          icon={IconList.PHONE}
          iconPosition="left"
          ariaLabel="0800 88 44 04"
          buttonId="phonebar_cta_phone"
          className="custom-phone-bar h-11 w-full"
        />
        <Button
          visual={ButtonVisual.SECONDARY}
          variation={ButtonVariation.SOLID}
          label="Angebot erstellen"
          link="link"
          size={ButtonSize.MEDIUM}
          type={ButtonType.WIZARD}
          icon={IconList.ARROW_RIGHT}
          iconPosition="left"
          buttonId="phonebar_cta_funnel"
          className="custom-phone-bar h-11 w-full"
        />
        <p className="col-span-2 text-center text-[15.5px] leading-4 tracking-tighter text-primary-800">
          <strong>0-24 Uhr:</strong> Beratung, Totenbeschau & Abholdienst
        </p>
      </span>
    </div>
  );
};

export default PhoneBar;
