"use client";

import { TDashboard } from "@/types/dashboard";
import { useEffect, useState } from "react";
import UserMenuObituaries from "../UserMenuObituaries";
import UserMenuPlannings from "../UserMenuPlannings";

type Props = {};

const MobileCommonUserMenu = (props: Props) => {
  const [userDashBoard, setUserDashboard] = useState<TDashboard>({
    obituaries: [],
    plannings: [],
  });

  useEffect(() => {
    loadApi();
  }, []);

  const loadApi = async () => {
    let api = await fetch(`/api/user/dashboard/`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
    setUserDashboard(api);
  };

  return (
    <div className="grid h-full w-full grid-cols-1 flex-col gap-2">
      <UserMenuObituaries obituaries={userDashBoard.obituaries} />
      <UserMenuPlannings plannings={userDashBoard.plannings} />
    </div>
  );
};

export default MobileCommonUserMenu;
