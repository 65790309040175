"use client";
import { CldImage } from "next-cloudinary";
import ItemError from "./error/ItemError";

interface ImageProps {
  src: string;
  width: number;
  height: number;
  alt?: string;
  sizes?: string;
  priority?: boolean;
  crop?: boolean;
  className?: string;
  quality?: number;
  dark?: boolean;
  base64?: `data:image/${string}`;
}

const CImage = ({
  src,
  width,
  height,
  alt,
  sizes,
  priority,
  crop,
  dark,
  quality,
  className,
  base64,
  ...props
}: ImageProps) => {
  let effects = [];
  // effects.push({ autoBrightness: true }),
  // effects.push({ autoColor: true }),
  // effects.push({ autoContrast: true }),
  // effects.push({ improve: true }),
  // effects.push({ sharpen: "30" });
  if (dark) {
    effects.push({ colorize: "30,co_rgb:000000" });
  }
  try {
    return (
      <CldImage
        width={width}
        height={height}
        src={src.replace(
          "https://res.cloudinary.com/dkyl4jbxx/image/upload/",
          ""
        )}
        crop={crop ? "fill" : "limit"}
        quality={quality && quality}
        format="auto"
        gravity={crop ? "auto" : ""}
        alt={alt && alt.length > 2 ? alt : "Description of my image"}
        sizes="(max-width: 320px) 50vw,(max-width: 480px) 50vw, 50vw"
        {...props}
        priority={priority ? priority : false}
        className={className ? className : ""}
        effects={effects}
        placeholder={base64 ? base64 : undefined}
      />
    );
  } catch (error) {
    return <ItemError __component="Image Error" />;
  }
};

export default CImage;
