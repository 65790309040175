import CImage from "@/components/CImage";
import { TObituaryFull } from "@/types/obituary";
import { dateIsoToLocaleString } from "@/utils/date";

const ObituaryItem = ({
  id,
  photo,
  birthDate,
  deathDate,
  slug,
  name,
}: TObituaryFull) => {
  return (
    <a
      href={`/traueranzeigen/${slug}`}
      className="flex flex-row items-center justify-start gap-2 bg-secondary-100/20 p-2 transition-all duration-300 hover:bg-secondary-100"
    >
      {photo && photo.url ? (
        <div className="bg-white">
          <CImage
            src={photo.url}
            height={48}
            width={48}
            className="h-12 w-12 min-w-[48px] object-cover"
          />
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col">
        <p className="line-clamp-1 font-semibold uppercase">{name}</p>
        <p className="text-sm">
          {dateIsoToLocaleString(birthDate)} -{" "}
          {dateIsoToLocaleString(deathDate)}
        </p>
      </div>
    </a>
  );
};

export default ObituaryItem;
