import { TObituaryFull } from "@/types/obituary";
import CreateNewObituary from "./CreateNewObituary";
import ObituaryItem from "./ObituaryItem";

type Props = {
  obituaries: TObituaryFull[];
};

const UserMenuObituaries = ({ obituaries }: Props) => {
  return (
    <div className="relative flex h-full flex-grow flex-col gap-1">
      <p className=" bg-white px-1 text-xs font-bold uppercase">
        Traueranzeigen
      </p>
      <div className="relative z-[2] flex h-full flex-col divide-y divide-secondary-200 overflow-hidden rounded-md border border-secondary-200 ">
        {obituaries.length === 0 && (
          <p className="my-auto p-6 text-center text-lg leading-5 ">
            Sie haben noch keine Traueranzeige erstellt
          </p>
        )}
        {obituaries.map((item, index) => {
          if (index < 3) {
            return <ObituaryItem {...item} />;
          }
        })}
        {obituaries.length >= 4 && (
          <a
            href="/dashboard"
            className="p-2 font-bold text-secondary hover:underline"
          >
            Und noch{" "}
            {obituaries.length === 4
              ? "1 Nachrufe"
              : `${obituaries.length - 3} Nachrufe`}
          </a>
        )}
        {/* <CreateNewObituary /> */}
      </div>
    </div>
  );
};

export default UserMenuObituaries;
