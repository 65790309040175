"use client";

export default function LoginButton() {
  const handlerClick = () => {
    window.location.href = `/api/auth/login/?returnTo=${encodeURIComponent(
      window.location.pathname
    )}`;
  };

  return (
    <div className="relative z-50 ml-auto mr-2 flex p-2 text-white">
      <a
        href={`/api/auth/login/?returnTo=${encodeURIComponent("/dashboard")}`}
        onClick={() => handlerClick()}
        className="cursor-pointer rounded-lg border border-white bg-transparent p-2 text-sm font-bold uppercase md:px-4"
      >
        MEIN BENU
      </a>
    </div>
  );
}
