"use client";
import CImage from "@/components/CImage";
import { useEffect, useState } from "react";

type Props = {};

type CarouselType = {
  url: string;
  address: string;
  zipcode: string;
};

const places: CarouselType[] = [
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1692819864/Margareten_Store_dd9a535b04.jpg",
    address: "Siebenbrunnengasse 9",
    zipcode: "1050 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1692819880/neubau_store_3bdf62f8f4.jpg",
    address: "Burggasse 5",
    zipcode: "1070 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1692819900/Ottakring_store_2a0c2dd28d.jpg",
    address: "Ottakringerstraße 229",
    zipcode: "1160 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1701105398/IMG_9135_9ce780bc1b.jpg",
    address: "Silbergasse 5",
    zipcode: "1190 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1692819846/Floridsdorf_store_bfe6441e4b.jpg",
    address: "Floridsdorfer Hauptstraße 28",
    zipcode: "1210 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1692819830/Donaustadt_store_9e94a2a9b0.jpg",
    address: "Schickgasse 32",
    zipcode: "1220 Wien",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1695041091/Linz_Store_1c23b362f4.jpg",
    address: "Magazingasse 5",
    zipcode: "4020 Linz",
  },
  {
    url: "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1695041134/Wels_Store_eace62b180.png",
    address: "Rablstraße 41",
    zipcode: "4600 Wels",
  },
];

const BenuComboStoresSlider = (props: Props) => {
  const [actual, setActual] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      incrementAtual();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const incrementAtual = () => {
    setActual((actual) => (actual + 1 === places.length ? 0 : actual + 1));
  };

  return (
    <div className="relative top-0 flex h-[165px] w-full bg-black-600">
      <CImage
        src={places[actual].url}
        width={200}
        height={150}
        alt={places[actual].address}
        className=" relative h-full w-full object-cover"
      />
      <span className="absolute bottom-0 flex h-auto w-full flex-row items-center justify-start bg-black/70 p-2 !text-[12px] font-semibold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          fill="none"
          viewBox="0 0 20 21"
          className="mr-1"
        >
          <path
            stroke="#FAFAFA"
            strokeLinecap="round"
            strokeWidth="2"
            d="M12.499 7.01l-3.217 3.24a.31.31 0 01-.442 0L7.5 8.9m9.166-.223c0 3.682-5 10-6.666 10-1.667 0-6.667-6.318-6.667-10a6.667 6.667 0 0113.333 0z"
          ></path>
        </svg>
        <p>
          {places[actual].address}
          <br /> {places[actual].zipcode}
        </p>
      </span>
    </div>
  );
};

export default BenuComboStoresSlider;
