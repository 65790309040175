"use client";
import Icons from "@/components/Icons";
import { IconList } from "@/types/icons";
import { StatsigSendEvent } from "@/utils/events";
import { getAdjustedPhoneNumber } from "@/utils/phone";
import React from "react";

type Props = {};

const DesktopNavBarPhone = (props: Props) => {
  return (
    <span className="z-10 flex flex-row">
      <a
        className="group z-50 flex w-auto flex-col items-start justify-start border-l border-white/40 pl-3 text-white"
        href={getAdjustedPhoneNumber().link}
        onClick={async () => {
          await StatsigSendEvent("phone-call");
        }}
      >
        <span className="inline-flex gap-1 text-[14px] uppercase leading-[1.2] ">
          <Icons icon={IconList.PHONE} />
          <span>24h erreichbar</span>
        </span>
        <span
          id="desktop-navbar-phone"
          className="text-[22px] font-bold leading-[1.2] decoration-white/50 underline-offset-4  group-hover:underline"
        >
          {getAdjustedPhoneNumber().label}
        </span>
      </a>
    </span>
  );
};

export default DesktopNavBarPhone;
