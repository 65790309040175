"use client";

import CImage from "@/components/CImage";
import { StatsigSendEvent } from "@/utils/events";
import { includeLinkSearchParams } from "@/utils/wizard";
import BenuComboStoresSlider from "./BenuComboStoresSlider";

type Props = {};

const BenuCombo = (props: any) => {
  const handlerEvent = async (buttonId: string) => {
    await StatsigSendEvent(buttonId);
  };

  return (
    <>
      <div className="grid-desktop-navbar">
        <a
          href={includeLinkSearchParams("/info/standorte")}
          target="_self"
          className="row-span-6"
          id="main-menu-main-menu-unternehmen-standorte"
          onClick={() => {
            handlerEvent("main-menu-/info/standorte/");
          }}
        >
          <div className="content">
            <p className="title">Unsere Standorte</p>
            <p>
              Als eines der größten privaten Bestattungsunternehmen des Landes
              sind wir auch in Ihrer Nähe mit einem Standort vertreten.
            </p>
          </div>
          <BenuComboStoresSlider />
        </a>
        <a
          href={includeLinkSearchParams("/ueber-uns")}
          target="_self"
          className=" row-span-3"
          id="main-menu-main-menu-unternehmen-ueber-uns"
          onClick={() => {
            handlerEvent("main-menu-/ueber-uns/");
          }}
        >
          {/* Ueber-Uns */}
          <div className="content">
            <p className="title">Über Uns</p>
            <p>
              Wir sind Ihr kompetenter Ansprechpartner für Bestattungen &
              Vorsorge. Hier erfahren Sie mehr über unsere Gründungsgeschichte
              und das Team hinter Benu.
            </p>
          </div>
        </a>
        <a
          href={includeLinkSearchParams("/produkte")}
          target="_self"
          className=" col-span-2 row-span-2"
          id="main-menu-main-menu-unternehmen-produkte"
          onClick={() => {
            handlerEvent("main-menu-/produkte/");
          }}
        >
          {/* Produkte */}
          <div className="content">
            <p className="title">Produkte</p>
            <ul className="">
              <li>Särge</li>
              <li>Urnen</li>
              <li>Blumenschmuck</li>
              <li>Partezettel</li>
              <li>Gedenkbilder</li>
              <li>Erinnerungsstücke</li>
            </ul>
          </div>
        </a>
        <a
          href={includeLinkSearchParams("/kundenbewertungen")}
          target="_self"
          className=" row-span-4"
          id="main-menu-main-menu-unternehmen-kundenbewertungen"
          onClick={() => {
            handlerEvent("main-menu-/kundenbewertungen/");
          }}
        >
          {/* Kundenbewertungen */}
          <div className="content">
            <p className="title">Kundenbewertungen</p>
            <p>Mehr als 2.100 Familien in Österreich vertrauen uns jährlich.</p>
            <CImage
              src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1697218554/assets/pngs/TrustIndexDark.png"
              width={174 * 1.6}
              height={29 * 1.6}
              alt="trust-index"
              className="mt-4 h-auto w-36"
              quality={90}
            />
            <span className="flex flex-row">
              <img
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685122223/assets/svgs/star.svg"
                className="h-5 w-5"
              />
              <img
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685122223/assets/svgs/star.svg"
                className="h-5 w-5"
              />
              <img
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685122223/assets/svgs/star.svg"
                className="h-5 w-5"
              />
              <img
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685122223/assets/svgs/star.svg"
                className="h-5 w-5"
              />
              <img
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1685122223/assets/svgs/star.svg"
                className="h-5 w-5"
              />
            </span>
            <p className="!text-[12px]">
              Basierend auf {props.customers} Bewertungen
            </p>
          </div>
        </a>
        <a
          href={includeLinkSearchParams("/traueranzeigen")}
          target="_self"
          className=" row-span-2"
          id="main-menu-main-menu-unternehmen-traueranzeigen"
          onClick={() => {
            handlerEvent("main-menu-/traueranzeigen/");
          }}
        >
          {/* Traueranzeigen */}
          <div className="content">
            <p className="title">Traueranzeigen</p>
            <p>
              Hier finden Sie unsere Gedenkseiten und digitalen Kondolenzbücher.
            </p>
          </div>
        </a>
        <a
          href={includeLinkSearchParams("/karriere")}
          target="_self"
          className=" row-span-3"
          id="main-menu-main-menu-unternehmen-karriere"
          onClick={() => {
            handlerEvent("main-menu-/karriere/");
          }}
        >
          {/* Karriere */}
          <div className="content">
            <p className="title">Karriere</p>
            <p>Wir sind immer auf der Suche nach Verstärkung.</p>

            <p>Werde ein Teil des Benu Teams.</p>
          </div>
        </a>
        <a
          href={includeLinkSearchParams("/fotogalerie")}
          target="_self"
          className=" row-span-2"
          id="main-menu-main-menu-unternehmen-fotogalerie"
          onClick={() => {
            handlerEvent("main-menu-/fotogalerie/");
          }}
        >
          {/* Fotogalerie */}
          <div className="content">
            <p className="title">Fotogalerie</p>
            <p>Weil ein Bild mehr sagt als 1000 Worte.</p>
          </div>
        </a>
      </div>
      <hr className="mx-4" />
      <div className="grid-desktop-articles ">
        {props.articles.articles.map((item: any) => (
          <a
            href={includeLinkSearchParams(`/ratgeber${item.slug}`)}
            className="item relative flex h-[128px] flex-col items-start justify-start "
          >
            <span className="relative h-[74px] w-full">
              <CImage
                src={item.featured_image.url}
                width={250}
                height={70}
                alt={item.title}
                className="h-full w-full object-cover"
              />
              <span className="absolute bottom-0 right-0 bg-secondary px-2 py-1 text-xs uppercase text-white">
                {item.category}
              </span>
            </span>
            <p className="top-0  p-2 text-sm">{item.title}</p>
          </a>
        ))}
        <a
          className="count"
          href={includeLinkSearchParams("/ratgeber/")}
          id="main-menu-main-menu-unternehmen-ratgeber"
          onClick={() => {
            handlerEvent("main-menu-/ratgeber/");
          }}
        >
          <p className="text-xl font-bold">Alle</p>
          <p className="text-2xl font-bold">Ratgeber</p>
          <p className="text-xl font-bold">Artikel</p>
        </a>
      </div>
    </>
  );
};

export default BenuCombo;
