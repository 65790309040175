import Icons from "@/components/Icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { TMobileMenuItem } from "@/types/menu";
import { includeLinkSearchParams } from "@/utils/wizard";
import { ChevronRight } from "lucide-react";

type Props = {
  value: string;
};

const MobileMenuItem = ({
  value,
  label,
  href,
  items,
  id,
}: Props & TMobileMenuItem) => {
  // console.log(items);
  return (
    <AccordionItem value={value}>
      {href ? (
        <a href={includeLinkSearchParams(href)} id={id} key={id}>
          {label}
        </a>
      ) : (
        <>
          <AccordionTrigger>{label}</AccordionTrigger>
          <AccordionContent>
            <span className="flex flex-col pt-2">
              {items?.map((item, index) => (
                <a
                  href={includeLinkSearchParams(item.href!)}
                  id={item.id}
                  key={item.id}
                >
                  <span
                    className={`flex w-full flex-row items-center gap-2 py-2 ${
                      item.icon ? "pl-0" : "pl-4"
                    } ${
                      item.icon ? "text-[17px]" : "text-[16px]"
                    } font-normal text-black-800`}
                  >
                    {item.icon ? (
                      <span className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-700 text-white">
                        <span className="scale-[85%]">
                          <Icons icon={item.icon} />
                        </span>
                      </span>
                    ) : (
                      <ChevronRight className="h-4 w-4 shrink-0 text-primary transition-transform" />
                    )}
                    {item.label}
                  </span>
                </a>
              ))}
            </span>
          </AccordionContent>
        </>
      )}
    </AccordionItem>
  );
};

export default MobileMenuItem;
