import { SubMenuItem } from "@/types/menu";
import React from "react";

type Props = {};

const ArticlesItem = (props: SubMenuItem) => {
  return <div className="h-full w-full">{JSON.stringify(props)}</div>;
};

export default ArticlesItem;
