"use client";
import Icons from "@/components/Icons";
import { IconList } from "@/types/icons";
import { TPlanning } from "@/types/planning";
import { StatsigSendEvent } from "@/utils/events";
import { FileDown } from "lucide-react";

type Props = {};

const PlanningItem = (props: TPlanning) => {
  const handlerDownload = () => {
    StatsigSendEvent("basic_pdf_download", props.uuid);
    window.open(
      `${process.env.NEXT_PUBLIC_PDF_SERVICE_URL}/offer/basic/${props.uuid}`
    );
  };

  return (
    <div
      onClick={() => handlerDownload()}
      className="group relative flex h-16 cursor-pointer flex-row items-center justify-center  gap-3 bg-white p-2"
    >
      <span className="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-full bg-tertiary text-white">
        <Icons icon={props.funeralType.toLocaleLowerCase() as IconList} />
      </span>
      <span className="flex w-full flex-col gap-1.5 text-secondary">
        <p className="text-xs leading-3">{props.orderId}</p>
        <p className="text-base font-bold uppercase leading-3">
          {getFuneralName(props.funeralType)}
        </p>
        <p className="text-xs leading-3">{props.creationDate}</p>
      </span>
      <span>
        <FileDown className="h-8 w-8 text-secondary-100 transition-all duration-300 group-hover:text-secondary-300" />
      </span>
    </div>
  );
};

const getFuneralName = (name: string): string => {
  switch (name) {
    case "CREMATION":
      return "Feuerbestattung";
    case "BURIAL":
      return "Erdbestattung";
    case "HOME_URN":
      return "Urne zu Hause";
    case "SEA_FUNERAL":
      return "Donaubestattung";
    case "TREE_FUNERAL":
      return "Baumbestattung";
    case "GEMSTONE_FUNERAL":
      return "Edelsteinbestattung";
    default:
      return "";
  }
};

export default PlanningItem;
