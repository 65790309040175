import { format } from "date-fns";
import { deAT } from "date-fns/locale";

export const dateFixIso = (str: string) => {
  str = str.replace(".000Z", "").replace("T00:00", "T12:00");
  return str;
};
export const dateIsoToString = (str: string) => {};
export const dateStringToIso = (str: string) => {};
export const dateIsoToLocaleString = (str: string) => {
  try {
    if (!str) return "";
    str = str.replace("Z", "").replace("T00:00", "T12:00");
    let ret = format(str, "dd.MM.y", {
      locale: deAT,
    });
    return ret;
  } catch (err) {
    return str;
  }
};
