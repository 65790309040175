"use client";

import CImage from "@/components/CImage";
import { MenuGroup, SubMenuGroup, TMenuItem } from "@/types/menu";
import { CaretDownIcon } from "@radix-ui/react-icons";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Link from "next/link";
import ArticlesItem from "./ArticlesItem";
import CommonItem from "./CommonItem";
import BenuCombo from "./Components/BenuCombo";
import FuneralTypeItem from "./FuneralTypeItem";

type Props = {
  className?: string;
  menu: MenuGroup;
};

const DesktopMenu = ({ menu }: Props) => {
  return (
    <>
      <NavigationMenu.Root
        className={`custom-navbar navbar-${menu.style} lg:pr-28 xl:pr-32 2xl:pr-48`}
      >
        <NavigationMenu.List className="center list">
          {menu.itemsDesktop.map((menuItem, index) => (
            <NavItem {...menuItem} key={`nav_item_${index}`} />
          ))}
          <NavigationMenu.Indicator className="indicator">
            <div className="" />
          </NavigationMenu.Indicator>
        </NavigationMenu.List>

        <div className="menu-area">
          <NavigationMenu.Viewport className="viewport" />
        </div>
      </NavigationMenu.Root>
    </>
  );
};

const NavItem = ({ label, component, href, id, groups }: TMenuItem) => {
  if (href) {
    return (
      <NavigationMenu.Item>
        <NavigationMenu.Link className="link" href={href} id={id}>
          {label}
        </NavigationMenu.Link>
      </NavigationMenu.Item>
    );
  } else if (component) {
    return (
      <NavigationMenu.Item>
        <NavigationMenu.Trigger className="trigger group">
          {label}
          <CaretDownIcon
            aria-hidden
            className="group-data-[state=open]:-rotate-180"
          />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className="content">
          <NavComponents {...component} />
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    );
  } else {
    return (
      <NavigationMenu.Item>
        <NavigationMenu.Trigger className="trigger group">
          {label}
          <CaretDownIcon
            aria-hidden
            className="group-data-[state=open]:-rotate-180"
          />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content className="content">
          {groups?.map((group: any, index: number) => (
            <div key={index}>
              {index > 0 && <hr className="mx-4" />}
              <NavGroups {...group} />
            </div>
          ))}
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    );
  }
};

const NavGroups = (group: SubMenuGroup) => {
  return (
    <ul className={`col-${group.col}${group.size ? ` ${group.size}` : ""}`}>
      {group.items.map((item) => {
        if (item.id === "main-menu-bestattungsarten-ubersicht") {
          return (
            <Link
              href={item.href ? item.href : ""}
              className="common col-span-2 flex h-[140px] !flex-row !gap-6 rounded !p-0"
            >
              <CImage
                src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1652270304/Trauerhalle_Urne_blau_handschuh_df6d829526.jpg"
                width={450}
                height={140}
                crop
                alt="w-1/2 h-auto"
              />
              <div className="flex h-full w-1/2 flex-col items-start justify-center gap-2">
                <p>{item.label}</p>
                <span>{item.extra ? item.extra : ""}</span>
              </div>
            </Link>
          );
        } else if (item.type === "articleItem") {
          return <ArticlesItem {...item} />;
        } else if (item.type === "articleCount") {
          return <ArticlesItem {...item} />;
        } else if (item.type === "funeralItem") {
          return <FuneralTypeItem {...item} />;
        } else {
          return <CommonItem {...item} />;
        }
      })}
    </ul>
  );
};

const NavComponents = (component: any) => {
  // console.log(component.type);
  switch (component.type) {
    case "benu_combo":
      return <BenuCombo {...component} />;
    default:
      return <></>;
  }
};

export default DesktopMenu;
