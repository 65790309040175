"use client";
import { DropdownMenuContent } from "@/components/ui/dropdown-menu";
import { TDashboard } from "@/types/dashboard";
import { BookPlus, HeartPulse, LayoutDashboard, LogOut } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import UserMenuObituaries from "./UserMenuObituaries";
import UserMenuPlannings from "./UserMenuPlannings";
type Props = {
  isAdmin: boolean;
};

const UserDropDownMenu = ({ isAdmin }: Props) => {
  const [userDashBoard, setUserDashboard] = useState<TDashboard>({
    obituaries: [],
    plannings: [],
  });

  useEffect(() => {
    loadApi();
  }, []);

  const loadApi = async () => {
    let api = await fetch(`/api/admin/user/profile/`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
    setUserDashboard(api);
  };

  return (
    <DropdownMenuContent className="relative right-24 grid w-[640px] grid-cols-2 flex-row gap-x-3 gap-y-1 p-2">
      <UserMenuObituaries obituaries={userDashBoard.obituaries} />
      <UserMenuPlannings plannings={userDashBoard.plannings} />
      {/* <div className="">
        <DropdownMenuLabel>Obituaries</DropdownMenuLabel>
        <div className="mb-4 grid w-full grid-cols-1 gap-2 rounded-lg border border-secondary-200 p-2">
          {userDashBoard.obituaries.map((item) => (
            <ObituaryItem {...item} />
          ))}
        </div>
        <CreateNewObituary />
      </div> */}
      <div className=""></div>
      <div className="col-span-2 flex flex-row gap-2 pt-2">
        <Link href="/dashboard" className="button medium secondary solid">
          <LayoutDashboard />
          ÜBERSICHT
        </Link>
        {isAdmin && (
          <Link
            href="/addons/generator"
            className="button medium primary solid"
          >
            <BookPlus />
            GENERATOR
          </Link>
        )}
        {/* {isAdmin && (
          <Link href="/addons/ks" className="button medium primary solid">
            <HeartPulse />
            KS
          </Link>
        )} */}
        <a
          href="/api/auth/logout"
          className="button medium tertiary solid ml-auto"
        >
          <LogOut />
          ABMELDEN
        </a>
      </div>
    </DropdownMenuContent>
  );
};

export default UserDropDownMenu;
