import { TPlanning } from "@/types/planning";
import PlanningItem from "./PlanningItem";

type Props = {
  plannings: TPlanning[];
};

const UserMenuPlannings = ({ plannings }: Props) => {
  return (
    <div className="relative flex h-full flex-grow flex-col gap-1">
      <p className=" bg-white px-1 text-xs font-bold uppercase">Planungen</p>
      <div className="relative z-[2] flex h-full flex-col divide-y divide-secondary-200 overflow-hidden rounded-md border border-secondary-200 ">
        {plannings.length === 0 && (
          <p className="my-auto p-6 text-center text-lg leading-5 ">
            Sie haben keine Planungen erstellt
          </p>
        )}
        {plannings.map((item, index) => {
          if (index < 3) {
            return <PlanningItem {...item} />;
          }
        })}
        {plannings.length >= 4 && (
          <a
            href="/dashboard"
            className="p-2 font-bold text-secondary hover:underline"
          >
            Und noch{" "}
            {plannings.length === 4
              ? "1 Planung"
              : `${plannings.length - 3} Planungen`}
          </a>
        )}
      </div>
    </div>
  );
};

export default UserMenuPlannings;
